import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { LoginRequest } from '../models/login.request';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = `https://manoreality.azurewebsites.net/api/auth`;
  }

  public login(request: LoginRequest): Observable<HttpResponse<User>> {
    const url = `${this.baseUrl}/login`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    });

    return this.http.post<User>(
      url,
      { fetchData: request.fetchData },
      { headers, withCredentials: false, observe: 'response' as 'response' }
    );
  }

  public validateUser(): Observable<HttpResponse<User>> {
    const url = `${this.baseUrl}/isLoggedIn`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: false,
      observe: 'response' as 'response',
    };
    return this.http.get<User>(url, httpOptions);
  }

  public logout(): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/logout`;
    return this.http.post<HttpResponse<any>>(url, {});
  }
}
