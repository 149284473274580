import { AuthState } from './auth.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authKey } from './auth.reducer';

const getAuthState = createFeatureSelector<AuthState>(authKey);

export const getCurrentUser = createSelector(
  getAuthState,
  (state) => state.user
);

export const isLoggedIn = createSelector(getCurrentUser, (user) => !!user);

export const isPremium = createSelector(
  getCurrentUser,
  (user) => user?.isPremium
);
