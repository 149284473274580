<div class="main-container">
  <mat-card class="container mat-elevation-z0">
    <form [formGroup]="form" class="form-container">
      <mat-form-field class="full-width" disabled>
        <mat-label disabled>Mail</mat-label>
        <input
          matInput
          formControlName="mainMail"
          placeholder="Mail"
          readonly
        />
      </mat-form-field>

      <!--      Features -->
      <div formGroupName="feature" class="container full-width">
        <h2>Nastavení mail komunikace</h2>

        <div style="margin-bottom: 1rem">
          <mat-slide-toggle formControlName="sendMail" labelPosition="before"
            >Příjimat mail</mat-slide-toggle
          >
        </div>

        <mat-form-field>
          <mat-label>Jak často chci dostávat mail</mat-label>
          <mat-select formControlName="intervalInMinutes">
            <mat-option
              *ngFor="let interval of allowedIntervals"
              [value]="interval.value"
            >
              {{ interval.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button
        mat-raised-button
        class="full-width"
        color="primary"
        (click)="updateUser()"
        [disabled]="!form.valid"
      >
        Uložit
      </button>
    </form>
  </mat-card>
</div>
