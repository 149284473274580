import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import {
  login,
  loginSuccess,
  logout,
  logoutSuccess,
  userIsAuthenticated,
  validateUser,
} from './auth.actions';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Action, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import {
  decreaseSpinnerCounter,
  increaseSpinnerCounter,
} from '../../spinner/redux/spinner.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((action) =>
        this.authService.login(action.request).pipe(
          map((user) => {
            this.store.dispatch(
              decreaseSpinnerCounter({ spinnerIdentifier: 'generalSpinner' })
            );
            this.router.navigate(['/overview']);
            return loginSuccess({ userMetadata: user.body });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  validateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateUser),
      switchMap(() =>
        this.authService.validateUser().pipe(
          map((user) => {
            return userIsAuthenticated({ user: user.body });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      switchMap(() =>
        this.authService.logout().pipe(
          map(() => {
            this.cookieService.delete('token');
            this.router.navigate(['/']);
            return logoutSuccess();
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private readonly cookieService: CookieService,
    private readonly store: Store
  ) {}
}
