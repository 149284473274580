import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRealityService } from '../../../../services/user-reality.service';
import { lastValueFrom } from 'rxjs';
import { UserUpdateRequest } from '../../../../models/user-request';
import { SelectItem } from '../../../models/select-item';

interface UserFeatureForm {
  intervalInMinutes?: number;
  sendMail: boolean;
}

@Component({
  selector: 'app-user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.scss'],
})
export class UserManagementEditComponent implements OnInit {
  public form: FormGroup;
  public allowedIntervals: SelectItem[] = [
    { label: 'Při novém výskytu', value: 1 },
    { label: 'Jednou denně', value: 24 * 60 },
    { label: '2x denně', value: 12 * 60 },
    { label: 'Jednou týdně', value: 7 * 24 * 60 },
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly userRealityService: UserRealityService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.initializeForm();
    await this.loadUser();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      mainMail: '',
      otherMails: [''],
      feature: this.fb.group<UserFeatureForm>({
        intervalInMinutes: undefined,
        sendMail: true,
      }),
    });
  }

  private async loadUser(): Promise<void> {
    const userReality = await lastValueFrom(this.userRealityService.getUser());
    this.form.patchValue(userReality);
  }

  public async updateUser(): Promise<void> {
    const userRequest: UserUpdateRequest = this.form.value as UserUpdateRequest;
    const updatedUser = await lastValueFrom(
      this.userRealityService.patchUser(userRequest)
    );

    this.form.patchValue(updatedUser);
  }
}
