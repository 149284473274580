import { Component } from '@angular/core';
import { CheckoutService } from '../services/checkout.service';
import { SubscriptionType } from '../models/payment/subscription-type';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss'],
})
export class SubscriptionPageComponent {
  public subscriptionType = SubscriptionType;
  public chosenSubscriptionPlan: SubscriptionType = SubscriptionType.Month;
  constructor(private checkoutService: CheckoutService) {}

  public async checkout(): Promise<void> {
    const checkoutResponse = await this.checkoutService.createCheckout(
      this.chosenSubscriptionPlan
    );
    window.open(checkoutResponse.checkoutUrl, '_self');
  }

  public choosePlan(chosenSubscriptionType: SubscriptionType): void {
    this.chosenSubscriptionPlan = chosenSubscriptionType;
  }
}
