import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SpinnerState } from './spinner.state';
import { spinnerFeatureKey } from './spinner.reducer';

const getSpinnerState = createFeatureSelector<SpinnerState>(spinnerFeatureKey);

const getSpinnerEntities = createSelector(
  getSpinnerState,
  (state) => state.entities
);

export const getShowSpinnerByIdentifier = (spinnerIdentifier: string) => {
  return createSelector(getSpinnerEntities, (state) => {
    if (state[spinnerIdentifier]) {
      return state[spinnerIdentifier]?.counter > 0;
    }
    return undefined;
  });
};
