<div class="container">
  <!--    TODO Don't use mat-card as we don't need it-->
  <mat-card class="card" *ngFor="let reality of realities">
    <mat-card-title-group>
      <img
        mat-card-image
        [src]="reality.imageUrl"
        alt="Image of reality"
        (click)="propertyClicked(reality)"
      />
    </mat-card-title-group>
    <mat-card-title (click)="propertyClicked(reality)">{{
      reality.name
    }}</mat-card-title>
    <mat-card-content>
      <p>
        {{ reality.price | currency: "CZK":"symbol-narrow":".0":"cs" }}
      </p>
      <p>{{ reality.address }}</p>
    </mat-card-content>
  </mat-card>
</div>
