import { PropertyType } from '../models/property-type';
import { SaleType } from '../models/sale-type';
import { SelectItem } from './models/select-item';
import { RadiusChip } from './models/radius-chip';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

export const propertyTypesItemsForMapping: SelectItem[] = [
  { label: 'Dům', icon: 'home', value: PropertyType.House },
  { label: 'Pozemek', icon: 'terrain', value: PropertyType.Land },
  { label: 'Byt', icon: 'apartment', value: PropertyType.Flat },
];

export const saleTypeChips: RadiusChip<SaleType>[] = [
  {
    label: 'Prodej',
    value: SaleType.Sale,
  },
  {
    label: 'Pronájem',
    value: SaleType.Rent,
  },
];

export const radiusChips: RadiusChip<number>[] = [
  {
    label: 'Přesně',
    value: 0,
  },
  {
    label: '5 Km',
    value: 5,
  },
  {
    label: '10 Km',
    value: 10,
  },
  {
    label: '20 Km',
    value: 20,
  },
];

export const optionsAutocomplete: Options = {
  bounds: undefined,
  origin: undefined,
  strictBounds: false,
  types: ['geocode'],
  componentRestrictions: { country: 'CZ' },
  fields: ['place_id', 'geometry', 'name', 'address_components'],
};
