import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PropertyRequest } from '../../../../models/property-request';
import { PropertyService } from '../../../../services/property.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-reality-edit',
  templateUrl: './user-reality-edit.component.html',
  styleUrls: ['./user-reality-edit.component.scss'],
})
export class UserRealityEditComponent implements OnInit {
  public realityIdentifier?: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly propertyService: PropertyService
  ) {}

  public async ngOnInit(): Promise<void> {
    const paramId = this.route.snapshot.paramMap.get('id');
    if (paramId !== 'new') {
      this.realityIdentifier = paramId;
    }
  }

  public async save(propertyRequest: PropertyRequest): Promise<void> {
    await lastValueFrom(
      this.propertyService.patchProperty(
        this.realityIdentifier,
        propertyRequest
      )
    );
  }
}
