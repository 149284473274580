import { Component } from '@angular/core';
import { PropertyRequest } from '../../../../models/property-request';
import { PropertyService } from '../../../../services/property.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-reality-new',
  templateUrl: './reality-new.component.html',
  styleUrls: ['./reality-new.component.scss'],
})
export class RealityNewComponent {
  constructor(private readonly propertyService: PropertyService) {}

  public async save(propertyRequest: PropertyRequest): Promise<void> {
    await lastValueFrom(this.propertyService.postProperty(propertyRequest));
  }
}
