<div class="list-container">
  <div *ngFor="let reality of realities">
    <mat-list-item>
      <div class="container" (click)="edit(reality.propertyIdentifier)" [ngClass]="reality.isActive ? 'active' : 'inactive'">
        <div class="item-header">
          <mat-icon
            aria-hidden="false"
            aria-label="icon"
            class="property-icon"
            >{{ propertyTypesMap.get(reality.propertyType)?.icon }}</mat-icon
          >
          <p>{{ propertyTypesMap.get(reality.propertyType)?.label }}</p>
        </div>
        <p class="item-sale">{{ saleTypesMap.get(reality.saleType) }}</p>
        <p class="item-address">{{ reality.localityResponse.address }}</p>
        <div class="item-edit">
          <mat-icon aria-hidden="false" aria-label="icon">
            navigate_next
          </mat-icon>
        </div>
      </div>
    </mat-list-item>
    <mat-divider class="divider"></mat-divider>
  </div>
  <div class="reality-button">
    <button
      (click)="new()"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
