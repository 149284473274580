import { Component, OnInit } from '@angular/core';
import { getShowSpinnerByIdentifier } from './redux/spinner.selectors';
import { Store } from '@ngrx/store';
import { SpinnerState } from './redux/spinner.state';
import { Observable } from 'rxjs';
import { hideSpinner } from './redux/spinner.actions';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  public showSpinner$: Observable<boolean> = this.store.select(
    getShowSpinnerByIdentifier('generalSpinner')
  );

  constructor(private readonly store: Store) {}

  public hideSpinner(): void {
    this.store.dispatch(hideSpinner());
  }
}
