import { Injectable } from '@angular/core';
import { Magic, RPCError, RPCErrorCode } from 'magic-sdk';
import {
  baseClientUrl,
  magicApiKey,
} from '../app.config';
import { login } from '../auth/redux/auth.actions';
import { Store } from '@ngrx/store';
import {
  decreaseSpinnerCounter,
  increaseSpinnerCounter,
} from '../spinner/redux/spinner.actions';

@Injectable({ providedIn: 'root' })
export class MagicService {
  private magic: any;
  constructor(private readonly store: Store) {
    this.magic = new Magic(magicApiKey, { locale: 'cs' });
  }

  public async loginWithMagic(mail: string): Promise<void> {
    try {
      this.turnOnSpinner();
      // const idToken = await this.magic.auth.loginWithMagicLink({
      //   email: mail,
      //   redirectURI: `${baseClientUrl}/callback`,
      // });

      const idToken = await this.magic.auth.loginWithEmailOTP({ email: mail });
      this.turnOffSpinner();
      this.store.dispatch(
        login({ request: { mail, token: idToken, fetchData: true } })
      );
    } catch (err) {
      this.turnOffSpinner();
      if (err instanceof RPCError) {
        switch (err.code) {
          case RPCErrorCode.MagicLinkFailedVerification:
          case RPCErrorCode.MagicLinkExpired:
          case RPCErrorCode.MagicLinkRateLimited:
          case RPCErrorCode.UserAlreadyLoggedIn:
            // TODO Handle error more accordingly (Marcel Novák, 26.12.2021)
            console.error('User could not be signed in.');
            break;
        }
      }
    }
  }

  public async loginWithCredential(): Promise<void> {
    this.magic.auth.loginWithCredential();
  }

  public preload(): void {
    this.magic.preload();
  }

  private turnOnSpinner(): void {
    this.store.dispatch(
      increaseSpinnerCounter({ spinnerIdentifier: 'generalSpinner' })
    );
  }

  private turnOffSpinner(): void {
    this.store.dispatch(
      decreaseSpinnerCounter({ spinnerIdentifier: 'generalSpinner' })
    );
  }
}
