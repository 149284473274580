import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRealityService } from '../../../../services/user-reality.service';
import { PropertyType } from '../../../../models/property-type';
import { lastValueFrom } from 'rxjs';
import { SaleType } from '../../../../models/sale-type';
import { SelectItem } from '../../../models/select-item';
import {
  optionsAutocomplete,
  propertyTypesItemsForMapping,
  radiusChips,
  saleTypeChips,
} from '../../../reality.config';
import { RadiusChip } from '../../../models/radius-chip';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { PropertyRequest } from '../../../../models/property-request';
import { UserPropertyResponse } from '../../../../models/user-reality/responses/user.response';

@Component({
  selector: 'app-reality-form',
  templateUrl: './reality-form.component.html',
  styleUrls: ['./reality-form.component.scss'],
})
export class RealityFormComponent implements OnInit {
  public form: FormGroup;
  public readonly propertyTypesItemsForMapping: SelectItem[] =
    propertyTypesItemsForMapping;
  public readonly saleTypeChips: RadiusChip<SaleType>[] = saleTypeChips;
  public readonly radiusChips: RadiusChip<number>[] = radiusChips;
  public readonly optionsAutocomplete: Options = optionsAutocomplete;
  @Input() public realityIdentifier?: string;
  @Output() public saveRequest: EventEmitter<PropertyRequest> =
    new EventEmitter<PropertyRequest>();
  public isEditMode: boolean;
  public userProperty: UserPropertyResponse;
  public isActiveProperty: boolean;
  public hasPostalCode = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly userRealityService: UserRealityService
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.realityIdentifier) {
      this.isEditMode = true;
    }

    this.initializeForm();
    await this.loadUser();
  }

  public handleAddressChange(address: Address): void {
    const county = address.address_components.find((ac) =>
      ac.types.includes('administrative_area_level_2')
    );
    const region = address.address_components.find((ac) =>
      ac.types.includes('administrative_area_level_1')
    );
    const postalCode = address.address_components.find((ac) =>
      ac.types.includes('postal_code')
    );
    const name = address.name;

    const street = address.address_components.find((ac) => ac.types.includes('route'));
    const streetNumber = address.address_components.find((ac) => ac.types.includes('street_number'));
    const premise = address.address_components.find((ac) => ac.types.includes('premise'));
    const city = address.address_components.find((ac) => ac.types.includes('locality'));
    const neighborhood = address.address_components.find((ac) => ac.types.includes('neighborhood'));
    const sublocality = address.address_components.find((ac) => ac.types.includes('sublocality_level_1'));

    const radius = this.form.get('locality').get('localityRadius')?.value;
    this.hasPostalCode = !!(postalCode?.long_name ?? null);

    const localityResponse = {
      address: name,
      postalCode: postalCode?.long_name ?? null,
      county: county?.long_name ?? null,
      region: region?.long_name ?? null,
      street: street?.long_name ?? null,
      streetNumber: streetNumber?.long_name ?? null,
      premise: premise?.long_name ?? null,
      city: city?.long_name ?? null,
      neighborhood: neighborhood?.long_name ?? null,
      sublocality: sublocality?.long_name ?? null,
      localityRadius: radius ?? 5
    };

    this.form.get('locality').setValue(localityResponse);
  }

  public async save(isActive: boolean = true): Promise<void> {
    const realityRequest: PropertyRequest = {
      ...(this.form.value as PropertyRequest),
      IsActive: isActive,
    };
    this.isActiveProperty = isActive;
    this.saveRequest.emit(realityRequest);
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      propertyType: PropertyType.House,
      saleType: [SaleType.Sale, Validators.required],
      maxPrice: undefined,
      minPrice: undefined,
      locality: this.fb.group({
        address: ['', Validators.required],
        county: undefined,
        region: undefined,
        postalCode: [undefined, Validators.required],
        localityRadius: 5,
        street: undefined,
        streetNumber: undefined,
        premise: undefined,
        city: undefined,
        neighborhood: undefined,
        sublocality: undefined
      }),
    });
  }

  private async loadUser(): Promise<void> {
    if (this.isEditMode) {
      this.userProperty = await lastValueFrom(
        this.userRealityService.getUserProperty(this.realityIdentifier)
      );
      this.isActiveProperty = this.userProperty.isActive;
      this.form.patchValue(this.userProperty);
      this.form.get('locality').patchValue(this.userProperty.localityResponse);
    }
  }
}
