import { MatFormFieldDefaultOptions } from '@angular/material/form-field';

export const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

const clientDomains = ['reulity.cz'];

export const apiUrl = 'https://manoreality.azurewebsites.net/api';
const clientProductionUrl = 'https://reulity.cz';
const clientLocalUrl = 'http://localhost:4200';

export const baseClientUrl =  clientDomains.includes(location.host) ? clientProductionUrl : clientLocalUrl;

export const magicApiKey = 'pk_live_9C38D4B13FE00193';
