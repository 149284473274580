<!--<div class="main-container">-->
  <mat-card class="container">
<!--    <mat-card-title class="parent-one">Co hledáte?</mat-card-title>-->
    <app-user-reality-form></app-user-reality-form>
  </mat-card>
<!--  <mat-accordion>-->
<!--    <mat-expansion-panel>-->
<!--      <mat-expansion-panel-header>-->
<!--        <mat-panel-title>O aplikaci</mat-panel-title>-->
<!--      </mat-expansion-panel-header>-->
<!--      <h1>Vítejte na Reulity!</h1>-->

<!--      <h2>Co je Reulity?</h2>-->
<!--      <p>-->
<!--        Jedná se o moderní agregátor nemovitostních inzerátů. Sledujeme inzeráty-->
<!--        na všech hlavních realitních stránkách a pomocí vašich nastavení filtru-->
<!--        vám doručíme ty nejlepší nabídky přímo na email.-->
<!--      </p>-->

<!--      <h3>Jak to funguje?</h3>-->
<!--      <ol>-->
<!--        <li>-->
<!--          <strong>Vyplnění Údajů:</strong> Vyplníte jednoduchý formulář s-->
<!--          kritérii, podle kterých hledáte nemovitost.-->
<!--        </li>-->
<!--        <li>-->
<!--          <strong>Potvrzení Emailu:</strong> Ověříte svůj email, který slouží-->
<!--          zároveň jako vaše přihlašovací údaje. Bez potřeby hesla.-->
<!--        </li>-->
<!--        <li>-->
<!--          <strong>Vyhledávání:</strong> Naše aplikace začne hledat nemovitosti-->
<!--          odpovídající vašemu filtru.-->
<!--        </li>-->
<!--        <li>-->
<!--          <strong>Notifikace:</strong> Jakmile najdeme odpovídající nemovitost,-->
<!--          dostanete o tom informaci na email nebo si to můžete zobrazit na naší-->
<!--          stránce.-->
<!--        </li>-->
<!--      </ol>-->

<!--      <h3>Proč Reulity?</h3>-->
<!--      <ul>-->
<!--        <li><strong>Snadná Registrace:</strong> Stačí potvrdit svůj email.</li>-->
<!--        <li>-->
<!--          <strong>Flexibilní Notifikace:</strong> Nastavte si, jak často chcete-->
<!--          dostávat upozornění.-->
<!--        </li>-->
<!--        <li>-->
<!--          <strong>Zdarma:</strong> V tuto chvíli je aplikace zdarma. O-->
<!--          zpoplatnění budeme uvažovat, pokud náklady na provoz vzrostou.-->
<!--        </li>-->
<!--      </ul>-->

<!--      <h3>Rychlé Tipy</h3>-->
<!--      <p>-->
<!--        <strong>Intenzivní Hledání:</strong> Nastavte si časté notifikace pro-->
<!--        rychlé informování o nových nabídkách.-->
<!--        <br />-->
<!--        <strong>Orientační Prohlížení:</strong> Pokud se jen orientujete,-->
<!--        nastavte si notifikace na 1x denně nebo 2x denně.-->
<!--      </p>-->

<!--      <h3>Máte Otázky?</h3>-->
<!--      <p>-->
<!--        Pokud máte zájem o posílání nabídek na více emailů nebo jiné dotazy,-->
<!--        napište nám na <a href="mailto:info@reulity.cz">info@reulity.cz</a>.-->
<!--      </p>-->
<!--    </mat-expansion-panel>-->
<!--    <mat-expansion-panel>-->
<!--      <mat-expansion-panel-header>-->
<!--        <mat-panel-title>Jak to vzniklo</mat-panel-title>-->
<!--      </mat-expansion-panel-header>-->
<!--      <p>-->
<!--        Začalo to když jsme s ženou hledali nové bydlení a museli jsme denně-->
<!--        procházet několik serverů, kde každý nabízel často různé nabídky. Bralo-->
<!--        to spoustu času a jelikož jsem vývojář, tak jsem si jedny Vánoce řekl,-->
<!--        že to musí jít nějak zautomatizovat. Rád bych napsal, že o pár měsíců-->
<!--        později, ale spíše to je po dvou letech, kdy už se nebojím projekt-->
<!--        zveřejnit. Do této doby jsme to s ženou občas vychytali. Teď otázka zda-->
<!--        nám to vůbec pomohlo. <b>Ano</b> pomohlo. Našli jsme díky tomu krásnou-->
<!--        řadovku poblíž města kde jsme vyrůstali. Nezůstalo to ale jen u toho-->
<!--        díky neustálým informacím z realitního trhu jsme se začali porozhlížet-->
<!--        po vlastním pozemku, kde bychom si mohli postavit dům. A tak jsme našli-->
<!--        pozemek za příjemnou cenu a teď už jen čekáme na příhodnou dobu k-->
<!--        postavení domu.-->
<!--      </p>-->
<!--    </mat-expansion-panel>-->
<!--  </mat-accordion>-->
<!--</div>-->
