import { HttpRequest } from '@angular/common/http';

export enum HttpMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Head = 'HEAD',
  Options = 'OPTIONS',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
}

export interface ExcludedPath {
  /**
   * Expression which must be included in the path for the URL to be ignored.
   */
  path: string | RegExp;

  /**
   * HTTP methods ignored by the interceptor.
   * If empty, all methods are ignored if the path matches.
   */
  methods?: HttpMethod[];
}

export function useInterceptor(
  req: HttpRequest<any>,
  requiredOrigin: string,
  excludedPaths?: ExcludedPath[]
): boolean {
  if (req.url?.includes(requiredOrigin)) {
    const requestPathName = new URL(req.url).pathname.replace(/\//, '');
    const isPathIgnored = excludedPaths?.some((excludedPath) => {
      const path = excludedPath.path;
      const matchesHttpMethod =
        !excludedPath.methods ||
        excludedPath.methods.includes(req.method as HttpMethod);

      if (path instanceof RegExp) {
        return matchesHttpMethod && requestPathName.match(path);
      }
      return matchesHttpMethod && requestPathName.includes(path);
    });
    return !isPathIgnored;
  }
  return false;
}
