import { Component, HostListener, OnInit } from '@angular/core';
import { RealityResponse } from '../../../models/reality.response';
import { RealityService } from '../../../services/reality.service';
import { FilterParamaters } from '../../../services/filter-paramaters';

@Component({
  selector: 'app-reality-list',
  templateUrl: './reality-list.component.html',
  styleUrls: ['./reality-list.component.scss'],
})
export class RealityListComponent implements OnInit {
  private top = 10;
  private skip = 0;
  public realities: RealityResponse[] = [];
  private hasMoreData = true;
  private isFetchingData = false;

  constructor(private readonly realityService: RealityService) {}

  public async ngOnInit(): Promise<void> {
    await this.fetchData();
    if (!this.realities.length) {
      setTimeout(() => {
        this.fetchData();
      }, 5000);
    }
  }

  @HostListener('window:scroll', [])
  async onScroll(): Promise<void> {
    if (
      window.innerHeight * 2 + window.scrollY >= document.body.scrollHeight &&
      this.hasMoreData &&
      !this.isFetchingData
    ) {
      this.isFetchingData = true;
      await this.fetchData('hide-spinner');
      this.isFetchingData = false;
    }
  }

  private async fetchData(spinnerIdentifier?: string): Promise<void> {
    const filterParams: FilterParamaters = {
      top: this.top,
      skip: this.skip,
    };

    // TODO Move to redux (Marcel Novák, 31.12.2021)
    const serverRealities = await this.realityService
      .getRealities(filterParams, { spinnerIdentifier })
      .toPromise();

    if (!serverRealities.length) {
      this.hasMoreData = false;
      return;
    }

    this.realities = this.realities.concat(serverRealities) ?? serverRealities;
    this.skip += this.top;
  }

  public propertyClicked(reality: RealityResponse): void {
    window.open(reality.propertyDetailUrl, '_blank');
  }
}
