import { FormGroup } from '@angular/forms';
declare module '@angular/forms' {
  interface FormGroup {
    getChangedValue: () => any;
  }
}

FormGroup.prototype.getChangedValue = function (this: FormGroup): any {
  const changedValue = {};
  for (const name in this.controls) {
    const control = this.controls[name];
    if (control.dirty) {
      changedValue[name] = control.value;
    }
  }
  return changedValue;
};
