import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserPropertyResponse } from '../../../../models/user-reality/responses/user.response';
import { lastValueFrom } from 'rxjs';
import { UserRealityService } from '../../../../services/user-reality.service';
import { SelectItem } from '../../../models/select-item';
import {
  propertyTypesItemsForMapping,
  saleTypeChips,
} from 'src/app/user/reality.config';
import { PropertyType } from '../../../../models/property-type';
import { SaleType } from '../../../../models/sale-type';
import { PropertyRequest } from '../../../../models/property-request';

@Component({
  selector: 'app-user-reality-list',
  templateUrl: './user-reality-list.component.html',
  styleUrls: ['./user-reality-list.component.scss'],
})
export class UserRealityListComponent implements OnInit {
  public realities: UserPropertyResponse[];
  public readonly propertyTypesMap: Map<PropertyType, SelectItem> = new Map<
    PropertyType,
    SelectItem
  >(propertyTypesItemsForMapping.map((pi) => [pi.value as PropertyType, pi]));
  public readonly saleTypesMap: Map<SaleType, string> = new Map<
    SaleType,
    string
  >(saleTypeChips.map((st) => [st.value, st.label]));

  constructor(
    private readonly router: Router,
    private readonly userRealityService: UserRealityService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.initializeUserRealities();
  }

  public async edit(propertyIdentifier: string): Promise<void> {
    await this.router.navigate([`/reality-setting`, propertyIdentifier]);
  }

  public async new(): Promise<void> {
    await this.router.navigate([`/reality-setting/new`]);
  }

  private async initializeUserRealities(): Promise<void> {
    this.realities = await lastValueFrom(
      this.userRealityService.getUserProperties()
    );
  }
}
