<div class="container">
  <h1 class="tittle">Unlock ...</h1>
  <!--  What it offers-->
  <section class="overview">
    <div class="advantage"></div>
  </section>

  <!--  Choosing plan-->
  <section class="plans">
    <div
      class="plan"
      (click)="choosePlan(subscriptionType.Month)"
      [class.checked-plan]="chosenSubscriptionPlan === subscriptionType.Month"
    >
      <div class="radio-plan">
        <mat-icon
          svgIcon="not-checked"
          *ngIf="
            chosenSubscriptionPlan !== subscriptionType.Month;
            else checked
          "
        ></mat-icon>
        <ng-template #checked>
          <mat-icon svgIcon="checked"></mat-icon>
        </ng-template>
      </div>
      <div class="text-plan">
        <div class="title-plan">39 Kč / měsíc</div>
        <div class="note-plan">30 dní zdarma</div>
      </div>
    </div>

    <div
      class="plan"
      (click)="choosePlan(subscriptionType.Quarterly)"
      [class.checked-plan]="
        chosenSubscriptionPlan === subscriptionType.Quarterly
      "
    >
      <div class="radio-plan">
        <mat-icon
          svgIcon="not-checked"
          *ngIf="
            chosenSubscriptionPlan !== subscriptionType.Quarterly;
            else checked
          "
        ></mat-icon>
        <ng-template #checked>
          <mat-icon svgIcon="checked"></mat-icon>
        </ng-template>
      </div>
      <div class="text-plan">
        <div class="title-plan">99 Kč / čtvrtletně</div>
        <div class="note-plan">33 Kč za měsíc</div>
      </div>
    </div>

    <div
      class="plan"
      (click)="choosePlan(subscriptionType.Yearly)"
      [class.checked-plan]="chosenSubscriptionPlan === subscriptionType.Yearly"
    >
      <div class="radio-plan">
        <mat-icon
          svgIcon="not-checked"
          *ngIf="
            chosenSubscriptionPlan !== subscriptionType.Yearly;
            else checked
          "
        ></mat-icon>
        <ng-template #checked>
          <mat-icon svgIcon="checked"></mat-icon>
        </ng-template>
      </div>
      <div class="text-plan">
        <div class="title-plan">299 Kč / ročně</div>
        <div class="note-plan">25 Kč za měsíc</div>
      </div>
    </div>
    <div class="plan-button" (click)="checkout()">
      <div class="plan-button-text">Vybrat</div>
    </div>
  </section>

  <section></section>
</div>
