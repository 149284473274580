<!--<section class="spinner" *ngIf="showSpinner$ | async as spinner">-->
<!--  <mat-spinner class="spinner__wrapper"></mat-spinner>-->
<!--</section>-->

<!--<section class="spinner" [class.spinner&#45;&#45;hidden]="!(showSpinner$ | async)">-->
<!--  <div class="lds-ripple spinner__positioning">-->
<!--    <div></div>-->
<!--    <div></div>-->
<!--  </div>-->
<!--</section>-->

<!--TODO Add own logo and use this spinner-->
<!--<section-->
<!--  class="spinner"-->
<!--  [class.spinner&#45;&#45;hidden]="!(showSpinner$ | async)"-->
<!--  (click)="hideSpinner()"-->
<!--&gt;-->
<!--  <h1>Spinner</h1>-->
<!--  <div class="spinner__wrapper">-->
<!--    <div class="spinner__logo">-->
<!--      <svg-->
<!--        class="spinner__circle"-->
<!--        viewBox="0 0 100 100"-->
<!--        xmlns="http://www.w3.org/2000/svg"-->
<!--      >-->
<!--        <circle class="spinner__circle-stroke" cx="50" cy="50" r="45" />-->
<!--      </svg>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->

<section
  class="spinner"
  [class.spinner--hidden]="!(showSpinner$ | async)"
  (click)="hideSpinner()"
>
  <div class="lds-roller spinner__positioning">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</section>
