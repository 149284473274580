import { AuthState } from './auth.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loginSuccess,
  logoutSuccess,
  userIsAuthenticated,
} from './auth.actions';

export const authKey = 'auth';

export const initialState: AuthState = {
  user: undefined,
};

const reducer = createReducer(
  initialState,
  on(loginSuccess, (state, action) => ({
    user: {
      mail: action.userMetadata.mail,
      isPremium: false,
    },
  })),
  on(logoutSuccess, () => initialState),
  on(userIsAuthenticated, (state, action) => ({
    user: {
      mail: action.user.mail,
      isPremium: false,
    },
  }))
);

export function authReducer(
  state: AuthState = initialState,
  action: Action
): AuthState {
  return reducer(state, action);
}
