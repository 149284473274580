import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { RealityListComponent } from './reality/page/reality-list/reality-list.component';
import { UserManagementEditComponent } from './user/management/page/user-management-edit/user-management-edit.component';
import { AuthGuard } from './auth/auth.guard';
import { ReverseAuthGuard } from './auth/reverse-auth.guard';
import { SubscriptionPageComponent } from './checkout/subscription-page.component';
import { UserRealityEditComponent } from './user/reality/page/user-reality-edit/user-reality-edit.component';
import { UserRealityListComponent } from './user/reality/page/user-reality-list/user-reality-list.component';
import { RealityNewComponent } from './user/reality/page/user-reality-new/reality-new.component';
import { PremiumAuthGuard } from './auth/premium-auth.guard';
import { SignOutMailComponent } from './mail/page/sign-out-mail/sign-out-mail.component';
import { LandingPageComponent } from './landing-page/landing.page.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [ReverseAuthGuard],
  },
  { path: 'login', component: LoginComponent, canActivate: [ReverseAuthGuard] },
  { path: 'callback', component: CallbackComponent },
  {
    path: 'overview',
    component: RealityListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user',
    component: UserManagementEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reality-setting',
    component: UserRealityListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reality-setting/new',
    component: RealityNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reality-setting/:id',
    component: UserRealityEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subscription',
    component: SubscriptionPageComponent,
    canActivate: [PremiumAuthGuard],
  },
  {
    path: 'sign-out/:id',
    component: SignOutMailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
