<div class="main-container">
  <mat-card class="container mat-elevation-z0">
    <form [formGroup]="form" class="form-container">
      <div class="toggle">
        <mat-button-toggle-group formControlName="propertyType">
          <mat-button-toggle
            *ngFor="let propertyType of propertyTypesItemsForMapping"
            [value]="propertyType.value"
          >
            <mat-icon aria-hidden="false" aria-label="icon">{{
              propertyType.icon
            }}</mat-icon>
            <mat-label>{{ propertyType.label }}</mat-label>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <app-chip
        class="chip-list"
        [chipValues]="saleTypeChips"
        formControlName="saleType"
      >
      </app-chip>

      <div formGroupName="locality">
        <section class="locality-container">
          <mat-form-field>
            <mat-label class="mat-body">Adresa</mat-label>
            <input
              ngx-google-places-autocomplete
              #placesRef="ngx-places"
              [options]="optionsAutocomplete"
              (onAddressChange)="handleAddressChange($event)"
              matInput
              formControlName="address"
              placeholder="Adresa"
            />
          </mat-form-field>

          <mat-form-field *ngIf="!hasPostalCode">
            <mat-label class="mat-body">PSČ</mat-label>
            <input
              matInput
              formControlName="postalCode"
              placeholder="PSČ"
            />
          </mat-form-field>

          <app-chip
            class="chip-list"
            [chipValues]="radiusChips"
            formControlName="localityRadius"
          ></app-chip>
        </section>
      </div>

      <section class="property-container">
        <mat-form-field class="full-width">
          <mat-label>Minimální cena</mat-label>
          <input
            matInput
            formControlName="minPrice"
            placeholder="Minimální cena"
            mask="separator"
            suffix=" Kč"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Maximální cena</mat-label>
          <input
            type="text"
            matInput
            formControlName="maxPrice"
            placeholder="Maximální cena"
            mask="separator"
            suffix=" Kč"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </mat-form-field>
      </section>

      <button
        mat-raised-button
        color="primary"
        (click)="save(isActiveProperty)"
        [disabled]="!form.valid"
      >
        {{ isEditMode ? "Upravit" : "Uložit" }}
      </button>
      <button
        *ngIf="isEditMode && this.userProperty"
        mat-raised-button
        [color]="isActiveProperty ? 'warn' : 'accent'"
        style="margin-top: 1rem"
        (click)="save(!isActiveProperty)"
      >
        {{ isActiveProperty ? "Vypnout sledování" : "Zapnout sledování" }}
      </button>
    </form>
  </mat-card>
</div>
