import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RealityResponse } from '../models/reality.response';
import { FilterParamaters } from './filter-paramaters';
import { RequestParameters } from './request-paramaters';
import { spinnerHeaderIdentifier } from '../spinner/spinner.config';

@Injectable({
  providedIn: 'root',
})
export class RealityService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = `https://manoreality.azurewebsites.net/api`;
  }

  public getRealities(
    filterParameters: FilterParamaters,
    requestParameters?: RequestParameters
  ): Observable<RealityResponse[]> {
    const url = `${this.baseUrl}/realities?top=${filterParameters.top}&skip=${filterParameters.skip}`;
    let headers: HttpHeaders = new HttpHeaders();
    if (requestParameters?.spinnerIdentifier) {
      headers = headers.append(
        spinnerHeaderIdentifier,
        requestParameters.spinnerIdentifier
      );
    }

    return this.http.get<RealityResponse[]>(url, { headers });
  }

  public getReality(realityIdentifier: string): Observable<any> {
    const url = `${this.baseUrl}/reality/${realityIdentifier}`;
    return this.http.get<any>(url);
  }
}
