import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';

export interface JwtToken {
  email: string;
  exp: number;
  iat: number;
  issuer: string;
  publicAddress: string;
}

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private readonly cookieService: CookieService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAuthentication();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAuthentication();
  }

  private checkAuthentication(): boolean {
    const token = this.cookieService.get('token');
    if (token) {
      const decoded = jwt_decode(token) as JwtToken;
      if (decoded.exp) {
        const now = new Date();
        return now.getTime() / 1000 <= decoded.exp;
      }
      return false;
    }
    return false;
  }
}
