<div class="container">
  <div class="content">
    <section id="what-is-reulity" class="section full-viewport">
      <div class="content-wrapper">
        <h2>Váš osobní lovec re(u)alit</h2>
        <p>Automatizované, personalizované vyhledávání nemovitostí na více platformách, vše na jednom místě.</p>
<!--        <ul class="benefits-list">-->
<!--          <li>Ušetřete čas a zvyšte své šance při hledání svého domova</li>-->
<!--          <li>Okamžitá upozornění, aby Vám nic neuniklo</li>-->
<!--          <li>Začněte vyplněním formuláře teď a tady! Hodně štěstí!</li>-->
<!--        </ul>-->
        <div>
          <img class="main-image" src="../../assets/landing-page/overview.png" alt="Main image">
        </div>
      </div>
    </section>

    <!-- Add more sections here -->
  </div>
  <div class="form-container">
    <app-user-reality-new-landing></app-user-reality-new-landing>
  </div>
</div>

<!-- New "What is Reulity" Section -->
<section id="what-is-reulity" class="reulity-section">
  <h2>Proč Reulity?</h2>
  <div class="reulity-cards">
    <div class="reulity-card">
      <span class="material-icons">person_add</span>
      <h3>Snadná registrace</h3>
      <p>Stačí potvrdit svůj email</p>
    </div>
    <div class="reulity-card">
      <span class="material-icons">notifications</span>
      <h3>Flexibilní notifikace</h3>
      <p>Nastavte si emailové notifikace jak přesně Vám to vyhovuje</p>
    </div>
    <div class="reulity-card">
      <span class="material-icons">money_off</span>
      <h3>Zdarma</h3>
      <p>Aktuálně je aplikace zdarma. O zpoplatnění budeme uvažovat v závislosti na nákladech na provoz</p>
    </div>
  </div>
</section>


<!-- New "How it Works" Section -->
<section id="how-it-works" class="how-it-works-section">
  <h2>Jak to funguje?</h2>

  <div class="step">
    <div class="step-number">1</div>
    <div class="step-content">
      <h3>Vyplňte formulář</h3>
      <p>Vyplníte jednoduchý formulář s požadavky, podle kterých hledáte nemovitost</p>
    </div>
    <img src="../../assets/landing-page/reulity-form.png" alt="Filling in the Form">
  </div>

  <div class="step">
    <div class="step-number">2</div>
    <div class="step-content">
      <h3>Potvrzení Emailu</h3>
      <p>Ověříte svůj email, který slouží zároveň jako vaše přihlašovací údaje. Bez potřeby hesla.</p>
    </div>
    <img src="../../assets/landing-page/confirm-mail.png" alt="Email Confirmation">
  </div>

  <div class="step">
    <div class="step-number">3</div>
    <div class="step-content">
      <h3>Vyhledávání</h3>
      <p>Naše aplikace začne hledat nemovitosti odpovídající vašemu filtru.</p>
    </div>
    <img src="../../assets/landing-page/snail-searching.jpeg" alt="Search">
  </div>

  <div class="step">
    <div class="step-number">4</div>
    <div class="step-content">
      <h3>Notifikace</h3>
      <p>Jakmile najdeme odpovídající nemovitost, dostanete o tom informaci na email nebo si ji můžete zobrazit na naší stránce.</p>
    </div>
    <img src="../../assets/landing-page/reulity-mail.png" alt="Notification">
  </div>
</section>

<!-- New "Quick Tips" Section -->
<section id="quick-tips" class="quick-tips-section">
  <h2>Rychlé tipy</h2>

  <div class="tip">
    <div class="tip-content">
      <h3>Flexibilní Nastavení Notifikací</h3>
      <p>Nastavte si časté notifikace pro intenzivní hledání nebo mírnější tempo pro orientační prohlížení.</p>
    </div>
    <img src="../../assets/landing-page/flexible-notification.png" alt="Intense Search Tip">
  </div>

  <div class="tip">
    <div class="tip-content">
      <h3>Více Reulity</h3>
      <p>V aplikaci máte možnost přidat až 5 různých reulit pro vyhledávání.</p>
    </div>
    <img src="../../assets/landing-page/reulity-search-overview.png" alt="Orientation Viewing Tip">
  </div>
  <div class="tip">
    <div class="tip-content">
      <h3>Ovládání Reulit</h3>
      <p>Jednotlivé reulity můžete podle potřeby zapínat nebo vypínat.</p>
    </div>
    <img src="../../assets/landing-page/control-reulity.png" alt="Orientation Viewing Tip">
  </div>
</section>

<!-- Footer or Questions Section -->
<section id="questions" class="footer">
  <h2>Kontaktujte nás</h2>
  <p>Pokud máte zájem o posílání nabídek na více emailů nebo jiné dotazy, napište nám na <a
    href="mailto:info@reulity.cz">info@reulity.cz</a>.</p>
</section>
