import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { JwtToken } from './auth.guard';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ReverseAuthGuard implements CanActivate {
  constructor(
    private readonly cookieService: CookieService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const token = this.cookieService.get('token');
    if (token) {
      const validatedUser = await this.authService.validateUser().toPromise();
      if (validatedUser.ok) {
        await this.router.navigate(['/overview']);
        return false;
      }
      return true;
    }
    return true;
  }
}
