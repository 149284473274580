import { HttpClient } from '@angular/common/http';
import { Checkout } from '../models/payment/checkout.response';
import { SubscriptionType } from '../models/payment/subscription-type';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = `https://manoreality.azurewebsites.net/api`;
  }

  public async createCheckout(
    subscriptionType: SubscriptionType
  ): Promise<Checkout> {
    const url = `${this.baseUrl}/create-checkout-session`;
    return await this.http
      .post<Checkout>(url, {
        subscriptionType,
      })
      .toPromise();
  }
}
