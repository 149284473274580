import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerState } from '../redux/spinner.state';
import { ExcludedPath, useInterceptor } from './spinner-interceptor.helper';
import {
  decreaseSpinnerCounter,
  increaseSpinnerCounter,
} from '../redux/spinner.actions';
import { spinnerHeaderIdentifier } from '../spinner.config';
import { apiUrl } from '../../app.config';

export const spinnerExcludedPathsToken = new InjectionToken<ExcludedPath[]>(
  'SPINNER_EXCLUDED_PATHS'
);

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<SpinnerState>) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // TODO Add supported URLs via DI
    if (useInterceptor(req, apiUrl)) {
      const spinnerIdentifier = req.headers.get(spinnerHeaderIdentifier);
      if (spinnerIdentifier) {
        const headers = req.headers.delete(spinnerIdentifier);
        req = req.clone({
          headers,
        });
      }

      this.store.dispatch(
        increaseSpinnerCounter({
          spinnerIdentifier: this.getSpinnerIdentifier(spinnerIdentifier),
        })
      );
      return next.handle(req).pipe(
        finalize(() => {
          this.store.dispatch(
            decreaseSpinnerCounter({
              spinnerIdentifier: this.getSpinnerIdentifier(spinnerIdentifier),
            })
          );
        })
      );
    }
    return next.handle(req);
  }

  private getSpinnerIdentifier(spinnerIdentifier: string): string {
    return spinnerIdentifier || 'generalSpinner';
  }
}
