import { Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { RadiusChip } from '../../user/models/radius-chip';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements ControlValueAccessor {
  public onChange: (value) => {};
  public onTouched: any;
  public value?: any;
  @Input() public chipValues: RadiusChip<any>[];

  constructor(@Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public onInputChange(event: RadiusChip<any>): void {
    this.value = event.value;
    this.onChange(event.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }

    this.value = value;
  }
}
