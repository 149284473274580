import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { appearance } from './app.config';
import {
  MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { LoginComponent } from './auth/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthResponseInterceptor } from './interceptors/auth-response.interceptor';
import { ActionReducerMap, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { RealityListComponent } from './reality/page/reality-list/reality-list.component';
import { RealityDetailComponent } from './reality/page/reality-detail/reality-detail.component';
import { UserRealityNewComponent } from './user/page/user-reality-new/user-reality-new.component';
import { UserManagementEditComponent } from './user/management/page/user-management-edit/user-management-edit.component';
import { UserRealityFormComponent } from './user/components/user-reality-form/user-reality-form.component';
import { AuthState } from './auth/redux/auth.state';
import { CallbackComponent } from './auth/callback/callback.component';
import { authReducer } from './auth/redux/auth.reducer';
import { AuthEffects } from './auth/redux/auth.effects';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthGuard } from './auth/auth.guard';
import { ReverseAuthGuard } from './auth/reverse-auth.guard';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerInterceptor } from './spinner/interceptors/spinner.interceptor';
import { HeaderComponent } from './header/header.component';
import { appInitializerFactory } from './app-initializer-factory';
import { MatMenuModule } from '@angular/material/menu';
import localeCs from '@angular/common/locales/cs';
import { spinnerReducer } from './spinner/redux/spinner.reducer';
import { SpinnerState } from './spinner/redux/spinner.state';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SubscriptionPageComponent } from './checkout/subscription-page.component';
import { UserRealityEditComponent } from './user/reality/page/user-reality-edit/user-reality-edit.component';
import { ChipComponent } from './ui/chip/chip.component';
import { UserRealityListComponent } from './user/reality/page/user-reality-list/user-reality-list.component';
import { RealityFormComponent } from './user/reality/components/reality-form/reality-form.component';
import { RealityNewComponent } from './user/reality/page/user-reality-new/reality-new.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { Router } from '@angular/router';
import { SignOutMailComponent } from './mail/page/sign-out-mail/sign-out-mail.component';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { LandingPageComponent } from './landing-page/landing.page.component';

registerLocaleData(localeCs);

export interface AppState {
  auth: AuthState;
  spinner: SpinnerState;
}

const reducers: ActionReducerMap<Partial<AppState>> = {
  auth: authReducer,
  spinner: spinnerReducer,
};

Sentry.init({
  dsn: 'https://d34c80f4455e4424b8d42a06afc97b3b@o1390409.ingest.sentry.io/6712203',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', 'https://reulity.cz'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: '0.1',
});

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CallbackComponent,
    RealityListComponent,
    RealityDetailComponent,
    UserRealityNewComponent,
    UserManagementEditComponent,
    UserRealityFormComponent,
    SpinnerComponent,
    HeaderComponent,
    SubscriptionPageComponent,
    UserRealityEditComponent,
    ChipComponent,
    UserRealityListComponent,
    RealityFormComponent,
    UserRealityNewComponent,
    RealityNewComponent,
    SignOutMailComponent,
    LandingPageComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatCardModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatOptionModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    NgxMaskModule.forRoot(),
    GooglePlaceModule,
    EffectsModule.forRoot([AuthEffects]),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictStateImmutability: false,
        strictStateSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 40,
    }),
    MatToolbarModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatExpansionModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'cs',
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Store],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
        dialogOptions: {
          lang: 'cs',
          labelName: 'Jméno',
        },
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    AuthGuard,
    ReverseAuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
