import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { login } from '../redux/auth.actions';
import { magicApiKey } from '../../app.config';
import { increaseSpinnerCounter } from '../../spinner/redux/spinner.actions';
import { MagicService } from '../../services/magic.service';

export interface UserMetadata {
  email: string;
}

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly magicService: MagicService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(
      increaseSpinnerCounter({ spinnerIdentifier: 'generalSpinner' })
    );
    // TODO Use magic service instead of event listener (Marcel Novák, 01.01.2022)
    // await this.magicService.loginWithCredential();

    this.createScript();
    window.addEventListener('@magic/ready', async (event: any) => {
      const { magic, idToken, userMetadata, oauth } = event.detail;
      this.store.dispatch(
        login({
          request: {
            mail: userMetadata.email,
            token: idToken,
            fetchData: true,
          },
        })
      );
    });
  }

  private createScript(): void {
    const script = this.renderer2.createElement('script') as HTMLScriptElement;
    script.src = `https://auth.magic.link/pnp/callback`;
    script.setAttribute('data-magic-publishable-api-key', magicApiKey);

    this.renderer2.appendChild(this.document.body, script);
  }
}
