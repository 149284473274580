<mat-toolbar class="toolbar" *ngIf="user$ | async as user; else loginButton">
  <div class="left">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="center">
    <img src="../../assets/logo/reulity.svg" alt="Reulity Logo" class="logo" />
    <span routerLink="/overview">{{ title }}</span>
  </div>
  <div class="right">
    <div class="ghost"></div>
    <!-- This will balance the hamburger menu -->
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/overview" type="button">
      <mat-icon>home</mat-icon>
      <span>Domů</span>
    </button>
    <button mat-menu-item routerLink="/reality-setting" type="button">
      <mat-icon>settings</mat-icon>
      <span>Mé ulity</span>
    </button>
    <button mat-menu-item routerLink="/user" type="button">
      <mat-icon>person</mat-icon>
      <span>Uživatel</span>
    </button>
    <button mat-menu-item routerLink="/subscription" *ngIf="user.isPremium">
      Předplatné
    </button>
    <button mat-menu-item (click)="logout()" type="button">
      <mat-icon>logout</mat-icon>
      <span>Odhlášení</span>
    </button>
  </mat-menu>
</mat-toolbar>
<ng-template #loginButton>
  <mat-toolbar>
    <img src="../../assets/logo/reulity.svg" alt="Reulity Logo" class="logo" />
    <span routerLink="/">{{ title }}</span>
    <span class="spacer"></span>

    <div class="login" routerLink="/login">
      <span>Přihlásit</span>
      <mat-icon>login</mat-icon>
    </div>
  </mat-toolbar>
</ng-template>
