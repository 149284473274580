import { Component, OnInit } from '@angular/core';
import { getCurrentUser, isLoggedIn } from '../auth/redux/auth.selectors';
import { Store } from '@ngrx/store';
import { logout } from '../auth/redux/auth.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public title = 'Reulity';
  public user$ = this.store.select(getCurrentUser);
  constructor(private readonly store: Store) {}

  ngOnInit(): void {}

  public async logout(): Promise<void> {
    await this.store.dispatch(logout());
  }

  public setTitle(newTitle: string): void {
    this.title = newTitle;
  }
}
