<div class="chip-list">
  <mat-chip-list>
    <mat-chip
      *ngFor="let chip of chipValues"
      (click)="onInputChange(chip)"
      [selected]="chip.value === value"
    >
      {{ chip.label }}
    </mat-chip>
  </mat-chip-list>
</div>
