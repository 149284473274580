<main
  class="main"
  [ngClass]="currentRoute === '/login' ? 'app-background' : ''"
>
  <div class="app-container">
    <app-header *ngIf="currentRoute !== '/login'"></app-header>
    <router-outlet></router-outlet>
  </div>
</main>
<app-spinner></app-spinner>

