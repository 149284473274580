import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = `https://manoreality.azurewebsites.net/api`;
  }

  public signOut(userIdentifier: string): Observable<any> {
    const url = `${this.baseUrl}/user/sign-out`;
    return this.http.post(url, { identifier: userIdentifier });
  }
}
