import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PropertyRequest } from '../models/property-request';
import { Observable } from 'rxjs';
import { UserPropertyResponse } from '../models/user-reality/responses/user.response';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = `https://manoreality.azurewebsites.net/api`;
  }

  public postProperty(
    propertyRequest: PropertyRequest
  ): Observable<UserPropertyResponse> {
    const url = `${this.baseUrl}/property`;
    return this.http.post<UserPropertyResponse>(url, propertyRequest);
  }

  public patchProperty(
    identifier: string,
    propertyRequest: PropertyRequest
  ): Observable<UserPropertyResponse> {
    const url = `${this.baseUrl}/property/${identifier}`;
    return this.http.patch<UserPropertyResponse>(url, propertyRequest);
  }
}
