import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Route, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'reulity';
  public currentRoute: string;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router
  ) {
    this.matIconRegistry.addSvgIcon(
      `checked`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/checked.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `not-checked`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/not-checked.svg'
      )
    );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => (this.currentRoute = ev.url));
  }
}
