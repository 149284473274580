import { Component, OnInit } from '@angular/core';
import { MagicService } from '../../services/magic.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  decreaseSpinnerCounter,
  increaseSpinnerCounter,
} from '../../spinner/redux/spinner.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private readonly magicService: MagicService,
    private readonly fb: FormBuilder,
    private readonly store: Store
  ) {
    this.initializeForm();
  }

  public ngOnInit(): void {
    this.magicService.preload();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      mail: ['@', Validators.required],
    });
  }

  private async loginWithMagic(mail: string): Promise<void> {
    await this.magicService.loginWithMagic(mail);
  }

  public async login(): Promise<void> {
    const request = this.form.value as { mail: string };
    await this.loginWithMagic(request.mail);
  }
}
