import { createAction, props } from '@ngrx/store';
import { SpinnerModel } from '../spinner.model';

export const increaseSpinnerCounter = createAction(
  '[App] increaseSpinnerCounter',
  props<{ spinnerIdentifier: string }>()
);
export const decreaseSpinnerCounter = createAction(
  '[App] decreaseSpinnerCounter',
  props<{ spinnerIdentifier: string }>()
);

export const hideSpinner = createAction('[App] hideSpinner');
