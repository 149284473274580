import { createAction, props } from '@ngrx/store';
import { UserMetadata } from '../callback/callback.component';
import { User } from '../../models/user';
import { LoginRequest } from '../../models/login.request';

export const login = createAction(
  '[Auth] login',
  props<{ request: LoginRequest }>()
);

export const loginSuccess = createAction(
  '[Auth] loginSuccess',
  props<{ userMetadata: User }>()
);

export const validateUser = createAction('[Auth] validateUser');
export const userIsAuthenticated = createAction(
  '[Auth] userIsAuthenticated',
  props<{ user: User }>()
);

export const logout = createAction('[Auth] logout');
export const logoutSuccess = createAction('[Auth] logoutSuccess');
