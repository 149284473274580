<mat-card-content class="parent-two">
  <form [formGroup]="form" class="form-container">
    <div
      formArrayName="PropertyRequests"
      *ngFor="let propertyRequest of propertyRequests.controls; let i = index"
    >
      <div [formGroupName]="i" class="form-group-container">
        <div class="toggle">
          <mat-button-toggle-group formControlName="PropertyType">
            <mat-button-toggle
              *ngFor="let propertyType of propertyTypesItemsForMapping"
              [value]="propertyType.value"
            >
              <mat-icon aria-hidden="false" aria-label="icon">{{
                propertyType.icon
              }}</mat-icon>
              <mat-label>{{ propertyType.label }}</mat-label>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="chip-list">
          <mat-chip-list>
            <mat-chip
              *ngFor="let saleType of saleTypeChips"
              (click)="selectSaleType(saleType)"
              [selected]="selectedSaleType?.value === saleType.value"
            >
              {{ saleType.label }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <div formGroupName="Locality">
          <section class="locality-container">
            <mat-form-field>
              <mat-label class="mat-body">Adresa</mat-label>
              <input
                ngx-google-places-autocomplete
                #placesRef="ngx-places"
                (onAddressChange)="handleAddressChange($event)"
                [options]="optionsAutocomplete"
                matInput
                formControlName="Address"
                placeholder="Adresa"
              />
            </mat-form-field>

            <mat-form-field *ngIf="!postalCode && localityRequest">
              <mat-label class="mat-body">PSČ</mat-label>
              <input
                matInput
                formControlName="PostalCode"
                placeholder="PSČ"
              />
            </mat-form-field>

            <mat-chip-list class="chip-list">
              <mat-chip
                *ngFor="let radius of radiusChips"
                (click)="selectRadius(radius)"
                [selected]="selectedRadius?.value === radius.value"
              >
                {{ radius.label }}
              </mat-chip>
            </mat-chip-list>
          </section>
        </div>
        <section class="property-container">
          <mat-form-field class="full-width">
            <mat-label>Minimální cena</mat-label>
            <input
              matInput
              formControlName="MinPrice"
              placeholder="Minimální cena"
              mask="separator"
              suffix=" Kč"
              inputmode="numeric"
              pattern="[0-9]*"
            />
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Maximální cena</mat-label>
            <input
              type="text"
              matInput
              formControlName="MaxPrice"
              placeholder="Maximální cena"
              mask="separator"
              suffix=" Kč"
              inputmode="numeric"
              pattern="[0-9]*"
            />
          </mat-form-field>
        </section>
      </div>
    </div>

    <mat-form-field class="full-width">
      <mat-label>Mail</mat-label>
      <input matInput formControlName="MainMail" placeholder="Mail" />
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      (click)="saveRequest()"
      [disabled]="!form.valid"
    >
      Uložit
    </button>
  </form>
</mat-card-content>
