import {
  decreaseSpinnerCounter,
  increaseSpinnerCounter,
  hideSpinner,
} from './spinner.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { SpinnerState } from './spinner.state';
import { SpinnerModel } from '../spinner.model';
import { createEntityAdapter } from '@ngrx/entity';

export const spinnerFeatureKey = 'spinner';

const adapter = createEntityAdapter<SpinnerModel>({
  selectId: (q) => q.identifier,
});

const initialState: SpinnerState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(increaseSpinnerCounter, (state, { spinnerIdentifier }) => {
    const spinner = state.entities[spinnerIdentifier];
    return adapter.upsertOne(
      {
        identifier: spinnerIdentifier,
        counter: spinner ? spinner.counter + 1 : 1,
      },
      state
    );
  }),
  on(decreaseSpinnerCounter, (state, { spinnerIdentifier }) => {
    const spinner = state.entities[spinnerIdentifier];
    return adapter.upsertOne(
      {
        identifier: spinnerIdentifier,
        counter: spinner ? Math.max(spinner.counter - 1, 0) : 0,
      },
      state
    );
  }),
  on(hideSpinner, () => adapter.getInitialState())
);
export function spinnerReducer(
  state: SpinnerState = initialState,
  action: Action
): SpinnerState {
  return reducer(state, action) as SpinnerState;
}
