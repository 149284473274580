import { Component, OnInit } from '@angular/core';
import { LocalityRequest } from '../../../models/locality-request';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { PropertyType } from '../../../models/property-type';
import { UserRequest } from '../../../models/user-request';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { UserResponse } from '../../../models/user-reality/responses/user.response';
import { UserRealityService } from '../../../services/user-reality.service';
import { MagicService } from '../../../services/magic.service';
import { select, Store } from '@ngrx/store';
import { isLoggedIn } from '../../../auth/redux/auth.selectors';
import { filter } from 'rxjs/operators';
import '../../../extensions/form.extensions';
import { SaleType } from '../../../models/sale-type';
import {
  optionsAutocomplete,
  propertyTypesItemsForMapping,
  radiusChips,
  saleTypeChips,
} from '../../reality.config';
import { SelectItem } from '../../models/select-item';
import { RadiusChip } from '../../models/radius-chip';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-reality-form',
  templateUrl: './user-reality-form.component.html',
  styleUrls: ['./user-reality-form.component.scss'],
})
export class UserRealityFormComponent implements OnInit {
  private isUserAuthenticated: boolean;
  public userReality?: UserResponse;
  public localityRequest?: LocalityRequest;
  public selectedRadius: RadiusChip<number> = {
    label: '20 Km',
    value: 20,
  };
  public selectedSaleType: RadiusChip<SaleType> = {
    label: 'Prodej',
    value: SaleType.Sale,
  };
  public form: FormGroup;
  public postalCode?: string;
  public get propertyRequests(): FormArray {
    return this.form.get('PropertyRequests') as FormArray;
  }

  public readonly optionsAutocomplete: Options = optionsAutocomplete;

  public propertyTypesItemsForMapping: SelectItem[] =
    propertyTypesItemsForMapping;

  public readonly saleTypeChips: RadiusChip<SaleType>[] = saleTypeChips;

  public readonly radiusChips: RadiusChip<number>[] = radiusChips;

  constructor(
    private readonly fb: FormBuilder,
    private readonly userRealityService: UserRealityService,
    private readonly magicService: MagicService,
    private readonly store: Store
  ) {
    this.initializeForm();
  }

  public async ngOnInit(): Promise<void> {
    await this.loadUserReality();
    this.magicService.preload();
  }

  public async saveRequest(): Promise<void> {
    const request = this.form.value as UserRequest;
    request.PropertyRequests[0].SaleType = this.selectedSaleType.value;

    this.localityRequest.LocalityRadius = this.selectedRadius.value;
    const postalCode = this.form.get('PropertyRequests.0.Locality.PostalCode')?.value ?? this.postalCode;
    request.PropertyRequests[0].Locality = {
      ...this.localityRequest,
      PostalCode: postalCode
    };

    if (this.isUserAuthenticated) {
      await lastValueFrom(this.userRealityService.patchUserReality(request));
    } else {
      request.LogUser = true;
      await lastValueFrom(this.userRealityService.postUserReality(request));
      await this.createRegistration(request.MainMail);
    }
  }

  public selectRadius(propertyTypeSelect: any): void {
    this.selectedRadius = propertyTypeSelect;
  }

  public selectSaleType(saleType: RadiusChip<SaleType>): void {
    this.selectedSaleType = saleType;
  }

  public handleAddressChange(address: Address): void {
    const county = address.address_components.find((ac) =>
      ac.types.includes('administrative_area_level_2')
    );
    const region = address.address_components.find((ac) =>
      ac.types.includes('administrative_area_level_1')
    );
    const postalCode = address.address_components.find((ac) =>
      ac.types.includes('postal_code')
    );
    const name = address.name;

    const street = address.address_components.find((ac) => ac.types.includes('route'));
    const streetNumber = address.address_components.find((ac) => ac.types.includes('street_number'));
    const premise = address.address_components.find((ac) => ac.types.includes('premise'));
    const city = address.address_components.find((ac) => ac.types.includes('locality'));
    const neighborhood = address.address_components.find((ac) => ac.types.includes('neighborhood'));
    const sublocality = address.address_components.find((ac) => ac.types.includes('sublocality_level_1'));
    this.postalCode = postalCode?.long_name ?? null;

    this.localityRequest = {
      Address: name,
      PostalCode: this.postalCode,
      County: county?.long_name,
      Region: region?.long_name,
      Street: street?.long_name,
      StreetNumber: streetNumber?.long_name,
      Premise: premise?.long_name,
      Neighborhood: neighborhood?.long_name,
      City: city?.long_name,
      Sublocality: sublocality?.long_name
    };

    if (this.postalCode) {
      this.form.get('PropertyRequests.0.Locality.PostalCode')?.setValue(this.postalCode);
    }
  }

  // TODO Use typed form from angular 14 (Marcel Novák, 06.07.2022)
  private initializeForm(): void {
    this.form = this.fb.group({
      MainMail: ['@', [Validators.required, Validators.email]],
      PropertyRequests: this.fb.array([this.createPropertyRequestControl()]),
    });
  }

  private createPropertyRequestControl(): FormGroup {
    return this.fb.group({
      Locality: this.fb.group({
        Address: ['', Validators.required],
        PostalCode: [undefined, Validators.required],
        County: undefined,
        LocalityRadius: 0,
      }),
      PropertyType: PropertyType.House,
      MaxPrice: undefined,
      MinPrice: undefined,
      SaleType: [SaleType.Sale, Validators.required],
    });
  }

  private async loadUserReality(): Promise<void> {
    this.store
      .pipe(
        select(isLoggedIn),
        filter((logged) => logged)
      )
      .subscribe(async (loggedIn: boolean) => {
        this.isUserAuthenticated = loggedIn;
        const userReality = await this.userRealityService.getUser().toPromise();

        if (userReality) {
          this.userReality = userReality;

          this.form.patchValue({
            MainMail: userReality.mainMail,
          });

          this.propertyRequests.clear();
        }
      });
  }

  private async createRegistration(mail: string): Promise<void> {
    await this.magicService.loginWithMagic(mail);
  }
}
