import { Store } from '@ngrx/store';
import { validateUser } from './auth/redux/auth.actions';

export function appInitializerFactory(store: Store): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      store.dispatch(validateUser());
      resolve();
    });
}
