import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  UserPropertyResponse,
  UserResponse,
} from '../models/user-reality/responses/user.response';
import { UserRequest, UserUpdateRequest } from '../models/user-request';

@Injectable({
  providedIn: 'root',
})
export class UserRealityService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = `https://manoreality.azurewebsites.net/api`;
  }

  public getUserProperties(): Observable<UserPropertyResponse[]> {
    const url = `${this.baseUrl}/users/properties`;
    return this.http.get<UserPropertyResponse[]>(url);
  }

  public getUserProperty(
    propertyIdentifier: string
  ): Observable<UserPropertyResponse> {
    const url = `${this.baseUrl}/users/properties/${propertyIdentifier}`;
    return this.http.get<UserPropertyResponse>(url);
  }

  public getUser(): Observable<UserResponse> {
    const url = `${this.baseUrl}/user`;
    return this.http.get<UserResponse>(url);
  }

  public patchUser(request: UserUpdateRequest): Observable<UserResponse> {
    const url = `${this.baseUrl}/user`;
    return this.http.patch<UserResponse>(url, request);
  }

  public postUserReality(request: UserRequest): Observable<UserResponse> {
    const url = `${this.baseUrl}/user`;
    return this.http.post<UserResponse>(url, request);
  }

  public patchUserReality(request: UserRequest): Observable<UserResponse> {
    const url = `${this.baseUrl}/user`;
    return this.http.patch<UserResponse>(url, request);
  }
}
