import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MailService } from '../../../services/mail.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-sign-out-mail',
  templateUrl: './sign-out-mail.component.html',
  styleUrls: ['./sign-out-mail.component.scss'],
})
export class SignOutMailComponent implements OnInit {
  private userIdentifier?: string;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly mailService: MailService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.userIdentifier = this.route.snapshot.paramMap.get('id');
    if (this.userIdentifier) {
      await lastValueFrom(this.mailService.signOut(this.userIdentifier));
    }
  }
}
