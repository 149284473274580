<div class="main-container">
  <form [formGroup]="form" class="form-container">
    <mat-card class="card-container">
      <img
        src="../../../assets/logo/reulity.svg"
        alt="Reulity Logo"
        class="logo"
      />
      <mat-card-title class="header">Welcome</mat-card-title>
      <div style="width: 100%">
        <div class="input-container">
          <mat-form-field class="form-field">
            <mat-label>Mail</mat-label>
            <input matInput formControlName="mail" placeholder="Mail" />
          </mat-form-field>
          <button
            class="login-button"
            mat-raised-button
            color="primary"
            [disabled]="!form.valid"
            (click)="login()"
          >
            Přihlásit se
          </button>
        </div>
      </div>
    </mat-card>
  </form>
</div>
